@import "../palette";

.custom-datepicker {

    @include mobile-lg- {
        padding-right: 0;
        width: 100%;
    }

    span {
        position: relative;

        input {
            background: #e4f2fe;
            border-radius: 5px;
            color: $primary;
            padding: 0 42px 0 13px;
            height: 36px;
            font-size: 16px;
            font-weight: 400;
            font-family: "Ubuntu", sans-serif;
            border: none;
            cursor: pointer;
            outline: none;
        }

        mat-icon {
            position: absolute;
            top: 9px;
            right: 10px;
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: $primary;
            cursor: pointer;
        }
    }

    p {
        margin: 0 15px;
        font-size: 16px;
        color: #949494;
    }
}
