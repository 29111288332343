@use "@angular/material" as mat;

$admin-dark-text: rgba(black, 0.87);
$admin-light-text: white;
$lightBlue: #e4f2fe;

// define a real custom palette (using http://mcg.mbitson.com)
$admin-mainblue-palette: (
    50: #e4f2fe,
    100: #bce0fb,
    200: #90cbf9,
    300: #64b6f7,
    400: #42a6f5,
    500: #2196f3,
    600: #1d8ef1,
    700: #1883ef,
    800: #1479ed,
    900: #0b68ea,
    A100: #ffffff,
    A200: #e1ecff,
    A400: #aeccff,
    A700: #95bcff,
    contrast: (
        50: $admin-dark-text,
        100: $admin-dark-text,
        200: $admin-dark-text,
        300: $admin-dark-text,
        400: $admin-dark-text,
        500: $admin-light-text,
        600: $admin-light-text,
        700: $admin-light-text,
        800: $admin-light-text,
        900: $admin-light-text,
        A100: $admin-dark-text,
        A200: $admin-dark-text,
        A400: $admin-dark-text,
        A700: $admin-dark-text
    )
);

$admin-altblue-palette: (
    50: #e0e8f0,
    100: #b3c6da,
    200: #80a1c2,
    300: #4d7baa,
    400: #265e97,
    500: #004285,
    600: #003c7d,
    700: #003372,
    800: #002b68,
    900: #001d55,
    A100: #87a5ff,
    A200: #547eff,
    A400: #2158ff,
    A700: #0845ff,
    contrast: (
        50: $admin-dark-text,
        100: $admin-dark-text,
        200: $admin-dark-text,
        300: $admin-light-text,
        400: $admin-light-text,
        500: $admin-light-text,
        600: $admin-light-text,
        700: $admin-light-text,
        800: $admin-light-text,
        900: $admin-light-text,
        A100: $admin-dark-text,
        A200: $admin-dark-text,
        A400: $admin-light-text,
        A700: $admin-light-text
    )
);

$admin-accent-palette: (
    50: #e2faf2,
    100: #b6f2dd,
    200: #85eac7,
    300: #54e1b1,
    400: #2fdaa0,
    500: #0ad48f,
    600: #09cf87,
    700: #07c97c,
    800: #05c372,
    900: #03b960,
    A100: #e2ffef,
    A200: #afffd3,
    A400: #7cffb8,
    A700: #63ffaa,
    contrast: (
        50: $admin-dark-text,
        100: $admin-dark-text,
        200: $admin-dark-text,
        300: $admin-dark-text,
        400: $admin-dark-text,
        500: $admin-dark-text,
        600: $admin-dark-text,
        700: $admin-dark-text,
        800: $admin-dark-text,
        900: $admin-light-text,
        A100: $admin-dark-text,
        A200: $admin-dark-text,
        A400: $admin-dark-text,
        A700: $admin-dark-text
    )
);

$admin-warn-palette: (
    50: #ffe9e9,
    100: #ffc9c8,
    200: #ffa5a4,
    300: #fe8080,
    400: #fe6564,
    500: #fe4a49,
    600: #fe4342,
    700: #fe3a39,
    800: #fe3231,
    900: #fd2221,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffcfce,
    A700: #ffb5b5,
    contrast: (
        50: $admin-dark-text,
        100: $admin-dark-text,
        200: $admin-dark-text,
        300: $admin-dark-text,
        400: $admin-dark-text,
        500: $admin-light-text,
        600: $admin-light-text,
        700: $admin-light-text,
        800: $admin-light-text,
        900: $admin-light-text,
        A100: $admin-dark-text,
        A200: $admin-dark-text,
        A400: $admin-dark-text,
        A700: $admin-dark-text
    )
);

$admin-primary:   mat.define-palette($admin-mainblue-palette);
$admin-secondary: mat.define-palette($admin-altblue-palette);
$admin-accent:    mat.define-palette($admin-accent-palette);
$admin-warn:      mat.define-palette($admin-warn-palette);

$primary:   mat.get-color-from-palette($admin-primary);
$secondary: mat.get-color-from-palette($admin-secondary);
$accent:    mat.get-color-from-palette($admin-accent);
$warn:      mat.get-color-from-palette($admin-warn);

$font-light: rgba(0, 0, 0, 0.47);
$font-medium: rgba(0, 0, 0, 0.67);
$font-dark: rgba(0, 0, 0, 0.87);