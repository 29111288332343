/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'components/controls';
@import 'components/custom-datepicker';
@import 'components/custom-snackbar';
@import 'components/mosaic-table';
@import 'components/side-menu';

// #########################
// GLOBAL SETTINGS
// #########################

html {
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
    background-color: #fafafa;

    @include mobile-lg- {
        background-color: #ffffff;
    }
}

// make sidenav full screen
html, body, material-app, mat-sidenav-container, .main-content {
    margin: 0;
    width: 100%;
    height: 100%;
    //background-color: mat-color($mat-blue-grey,  50);
}

body {
    font-family: "Ubuntu", sans-serif;
}

a {
    &:not(.mat-tab-link, .mat-line){
        color: $primary;
    }

    &.mat-tab-link {
        &.mat-tab-label-active {
            color: $primary;
            opacity: 1;
        }
    }
}

.admin {

    .admin-subnav {
        height: 120px;
        display: flex;
        align-items: flex-start;
        .admin-subheader {
            width: 80%;
            margin: 24px auto;
            text-indent: -34px;
        }
    }
    .admin-content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -49px;
        background: #fff;
        padding-left: 0;
        padding-right: 0;
    }
    
    .admin-contentnav {
        top: -24px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: -24px;
    }

    .detail-panel {
        padding: 24px;
        border-left: 1px solid #ddd;
        height: 100%;    
    }
}

.mat-button, .mat-raised-button {
    font-size: 13px !important;
    letter-spacing: 0.15pt !important;
    border-radius: 5px !important;

    ::ng-deep {
        .mat-button-wrapper {
            i, svg {
                vertical-align: text-bottom;
            }
        }
    }
}

.muted {
    color: mat.get-color-from-palette(mat.$blue-grey-palette, 200);
    a {
        color: mat.get-color-from-palette(mat.$light-blue-palette, 400);
    }

    &:hover {
        color: mat.get-color-from-palette(mat.$blue-grey-palette, 500);
    }
}

.login-screen {
    .mat-drawer-content {
        padding-top: 0px !important;
    }
}

.mat-drawer-content {
    padding-top: 75px;
    box-sizing: border-box;

    @include mobile-lg- {
        padding-top: 0;
    }

    .mat-toolbar {
        background: #fafafa !important;
        display: none;
    }
}

.mat-drawer {
    border: none !important;
    background: none;
}

.mat-drawer-container {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 1);

    @include mobile-lg- {
        background-color: #ffffff;
    }
}

.mat-dialog-container {
    border-radius: 8px !important;
}

.dialog-wrapper {
    position: relative;
    .btn-close {
        position: absolute;
        top: 12px;
        right: 0;
        color: #fff;
    }

    header {
        h2 {
            font-size: 20px;
            margin-bottom: 5px;
        }
    }

    .body {
        padding: 0;

        @include mobile-lg- {
            max-height: 350px !important;
            overflow-y: scroll;
        }
    }

    footer {
        padding-top: 25px;
    }
}

.table {
    width: 100%;
    text-align: left;
}

mat-cell, mat-header-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;

    &:last-child {
        padding-right: 0;
    }
}

// #########################
// MAT COMPONENT STYLES
// #########################

// make all buttons uppercase
.mat-button, .mat-raised-button {
    text-transform: uppercase;
    font-size: .7rem;
}

.mat-list-item {
    &.active {
        background: #e4f2fe;
        color: $primary;
    }
}

// fix an overflow issue with checkboxes in a table
.mat-table {
    border-collapse: collapse;

    .mat-header-row {
        border-bottom: 2px solid $primary !important;
    }

    .cell-checkbox {
        overflow: visible;
    }
    .mat-row:last-child {
        border: none;
    }
}

// remove the underline from certain form inputs
.no-underline {
    .mat-form-field-underline { display: none}
}

// dialog styles
.dialog-header {
    background: $primary;
    color: #fff;
    margin: -24px -24px auto;
    padding: 48px 24px;
    .dialog-header-ico {
        font-size: 75px;
        height: 75px;
        width: 75px;
        margin-right: 12px;
    }
    h2, p {
        margin: 0;
    }
}

// Stepper styles
.mat-horizontal-stepper-header-container {
    background: mat.get-color-from-palette(mat.$blue-grey-palette, 50);
    margin: 0 -24px 24px;
}
.stepper-actions {
    margin-top: 34px;
}

//Mat divider
.mat-card {
    border-radius: 8px !important;
    box-shadow: none;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.05) !important;
    // margin-top: 40px !important;

    @include mobile-lg- {
        border-radius: 0 !important;
        margin-top: 0 !important;
        box-shadow: none !important;
        width: 100% !important;
        padding-bottom: 75px !important;
    }

    .mat-divider {
        position: relative !important;
    }
}



// #########################
// SECTION PANEL STYLES
// #########################
.section-panel {
    padding: 20px 0;
    border-bottom: 1px solid mat.get-color-from-palette(mat.$blue-grey-palette, 50);
    .section-header {
        margin-bottom: 20px;
        .section-headline {
            font-size: 21px;
        }
    }
    .section-content {
        .content-panel {
            .content-panel-details {
                padding-bottom: 10px;
                margin-bottom: 30px;
                border-bottom: 1px solid mat.get-color-from-palette(mat.$blue-grey-palette, 50);
                .caption {
                    color: mat.get-color-from-palette(mat.$blue-grey-palette, 200);
                    font-size: 12px;
                    margin: 5px 0 20px;
                }

                .mat-form-field-wrapper {
                    .mat-form-field-infix {
                        width: auto;
                    }
                }
            }
            &:last-child {
                .content-panel-details {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }
    }
    .section-footer {
        padding: 20px 0;
    }
}

// No [item] created yet message
.none {
    display: block;
    background: #f2f2f2;
    padding: 24px;
    border-radius: 5px;
    color: #949494;
    text-align: center;
    font-size: 12pt;

    button {
        margin-top: 20px;
    }
}

.css-1ccho2k, .css-1cln8oj {
    background: #0f94f6;
    font-family: "Ubuntu", sans-serif;
    border-radius: 5px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

    @include tablet-lg- {
        right: 90px !important;
        bottom: 26px !important;
    }

    @include mobile-lg- {
        display: none !important;
    }
}

::ng-deep {
    .mat-progress-bar {
        border-radius: 100px;
    }
}

.mat-progress-bar {
    border-radius: 100px !important;
}

// Color scheme for report filter options
.mat-option {
    color: #000000;

    &.multi-line-option {
        height: 60px !important;

        .mat-option-text {
            h4 {
                margin-top: 10px;
                margin-bottom: -15px;
                font-weight: 500;

                span {
                    display: none;
                }
            }

            span {
                opacity: 0.5;
                font-size: 13px;
            }
        }
    }

    &.mat-active {
        color: #777777;
    }

    &.no-checkbox {
        mat-pseudo-checkbox {
            display: none;
        }
    }

    b {
        display: inline-block;
        color: white;
        background-color: #e7e7e7;
        font-size: 7pt;
        font-weight: bold;
        font-style: italic;
        text-align: center;
        padding-right: 1px;
        width: 27px;
        height: 18px;
        line-height: 2;
        border-radius: 2px;
        margin-right: 5px;
        vertical-align: text-bottom;
        text-transform: uppercase;
    }



    &.ss {
        &.mat-selected {
            color: #2196f3 !important;
            font-weight: 500 !important;

            b {
                background: #2196f3;
            }
        }
    }

    &.sub {
        &.mat-selected {
            color: #004285 !important;
            font-weight: 500 !important;

            b {
                background: #004285;
            }
        }
    }

    &.pts {
        &.mat-selected {
            color: #EC368D !important;
            font-weight: 500 !important;

            b {
                background: #EC368D;
            }
        }
    }

    &.ref {
        &.mat-selected {
            color: #0AD48F !important;
            font-weight: 500 !important;

            b {
                background: #0AD48F;
            }
        }
    }

    &.fail {
        &.mat-selected {
            color: #F25757 !important;
            font-weight: 500 !important;

            b {
                background: #F25757;
            }
        }
    }
}

.capitalize {
    text-transform: capitalize;
}

.zEWidget-launcher {
    @include mobile-lg- {
        display: none;
    }
}

.connections-mobile-menu {
    mat-list-item {
        display: block;
        color: #aaaaaa;
        padding: 10px 15px;
        margin-top: 15px;

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.mat-button {
    &.light-btn {
        ::ng-deep {
            .mat-button-focus-overlay {
                opacity: 1;
            }
        }
    }
}

.cdk-overlay-pane {
    @include mobile-lg- {
        max-width: 90vw !important;
    }

    .mat-dialog-container, .mat-bottom-sheet-container {
        padding: 24px;
        max-height: 90vh;

        header {
            margin-bottom: 34px;

            h1 {
                margin: 0;
            }

            span {
                font-size: 24px;
                color: #aaaaaa;
                cursor: pointer;
                padding: 5px;

                &.disabled {
                    pointer-events: none;
                    cursor: default;
                }
            }
        }

        section {
            margin-bottom: 20px;

            h3 {
                font-size: 16px;
                color: $primary;
            }
        }
    }
}

.truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wrap {
    min-width: 0;
    white-space: normal;
}

.display-block {
    display: block !important;
}

.bar-padding {
    margin: 10px 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.wider-panel.mat-select-panel {
    max-width: 350px;
}

.mat-tooltip {
    line-height: 1.5 !important;
}

.error-snackbar {
    .mat-button {
        color: #F25757!important;
    }
}