@import "../palette";

.side-menu {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 16px;
        margin-bottom: 50px;

        &:last-of-type {
            margin-bottom: 0;
        }

        h4 {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: .1em;
            line-height: 1;
            font-weight: 500;
            display: block;
            margin-bottom: 16px;
        }

        li {
            background: rgba(232, 244, 254, 0.0);
            color: #aaaaaa;
            padding-left: 37px;
            padding-right: 20px;
            height: 38px;
            transform: translateX(-40px);
            border-radius: 0 5px 5px 0;
            display: flex;
            align-items: center;
            transition: all 0.2s ease;
            margin-bottom: 5px;
            cursor: pointer;
            outline: none !important;

            &:hover, &.active {
                background: rgba(232, 244, 254, 1.0);
                color: $primary;
            }

            svg {
                margin-right: 16px;
            }

            &.disabled {
                color: #aaaaaa;
                opacity: 0.5;
                background: #FFFFFF;
                cursor: default;
                pointer-events: none;

                &.hover {
                    color: #aaaaaa;
                    opacity: 0.5;
                    background: #FFFFFF;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }
}
