@import "../palette";

.search-field {
    position: relative;

    @include tablet- {
        margin-bottom: 24px;
    }

    input {
        height: 36px;
        box-sizing: border-box;
        min-width: 350px;
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        padding: 0px 10px;
        padding-left: 35px;
        font-family: 'Ubuntu', sans-serif;
        outline: none;
        transition: all 0.3s ease;

        @include tablet- {
            min-width: 100%;
        }

        &::placeholder {
            color: #aaaaaa;
        }

        &:focus {
            border: 2px solid $primary;
            padding-left: 34px;
        }
    }

    span {
        position: absolute;
        left: 10px;
        top: 8px;
        color: #aaaaaa;
    }
}

.mosaic-input {
    position: relative;

    @include tablet- {
        margin-bottom: 24px;
    }

    &.inline {
        display: inline-block;
    }

    input, textarea {
        height: 36px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        padding: 0px 10px;
        font-family: 'Ubuntu', sans-serif;
        outline: none;
        transition: all 0.3s ease;

        @include tablet- {
            min-width: 100%;
        }

        &::placeholder {
            color: #aaaaaa;
        }

        &:focus {
            border: 2px solid $primary;
            padding-left: 9px;
        }
    }

    textarea {
        min-height: 50px;
        padding: 5px 10px;
        line-height: 1.3;
        resize: vertical;

        @include tablet- {
            min-width: 100%;
        }

        &::placeholder {
            color: #aaaaaa;
        }

        &:focus {
            border: 2px solid $primary;
            padding-left: 9px;
        }
    }

    &.prefix {
        input {
            padding-left: 30px;

            &:focus {
                border: 2px solid $primary;
                padding-left: 29px;
            }
        }

        span {
            position: absolute;
            left: 10px;
            top: 8px;
            color: #000000;
        }
    }

    &.suffix {
        input {
            padding-right: 35px;

            &:focus {
                border: 2px solid $primary;
                padding-left: 9px;
            }
        }

        span {
            position: absolute;
            right: 10px;
            top: 8px;
            color: #000000;
        }
    }

    &.full-width {
        input {
            width: 100% !important;
        }
    }

    &.locked {
        display: inline-block;

        &::after {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            content: url('/assets/img/lock-solid.svg');
            width: 10px;
            fill: #000000;
            position: absolute;
            right: 13px;
            top: 9px;
            opacity: 0.5;
            font-size: 10px;
        }

        &.suffix {
            span {
                right: 30px !important;
            }

            input {
                width: 120px !important;
            }
        }
    }

    .hint {
        font-size: 10px;
        color: #AAAAAA;
        text-align: right;
        display: block;
    }

    &.error {
        input {
            border: 2px solid $warn;
        }
    }
}

.sort-button, .mosaic-dropdown {
    position: relative;

    &::after {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: url('/assets/img/caret-down-solid.svg');
        width: 10px;
        fill: #000000;
        position: absolute;
        right: 13px;
        top: 10px;
    }

    &.locked {
        pointer-events: none;
        cursor: default;

        &::after {
            content: url('/assets/img/lock-solid.svg');
            opacity: 0.5;
            width: 10px;
            top: 9px;
        }

        select:disabled {
            color: #000000;
        }
    }

    span {
        color: #757575;
        margin-right: 10px;
    }

    select {
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        background: transparent;
        height: 36px;
        appearance: none;
        -webkit-appearance: none;
        padding: 0 33px 0 13px;
        outline: none;
        cursor: pointer;
    }
}

.control-save {
    background-color: #e4f2fe;
    height: 60px;
    padding: 0 13px 0 25px;
    border-radius: 6px;
    margin-bottom: 30px;

    svg {
        margin-right: 5px;
    }

    p {
        color: $primary;
        margin: 0 !important;
    }
}
