@import "../palette";

.mat-table {
    &.mosaic-table {
        .mat-header-row {
            th.mat-header-cell {
                border-bottom: 2px solid #2196f3 !important;
            }
        }
    }
}
